import React from 'react';
import { Link } from 'gatsby';

/*
  The sidenav is not loaded by default on the main pages. To include this navigation you can
  add "sidenav: true" in the front-matter of your markdown pages
*/

const Sidenav = () => (
  <aside className="usa-layout-docs-sidenav desktop:grid-col-3 padding-bottom-4">
        <nav aria-label="Secondary navigation">
        <ul class="usa-sidenav">
            <li class="usa-sidenav__item">
            <a href="#ref_over_time" class="usa-current">Referrals Over Time</a>
        </li><li class="usa-sidenav">
            <a href="#ref_by_agency">Referrals By Law Enforcement Agency</a>
        </li><li class="usa-sidenav__item">
            <a href="#ref_by_offense_type">Referrals By Offense Type</a>
        </li>
        <li class="usa-sidenav__item">
            <a href="##dem_break">Demographic breakdown</a>
        </li>
        </ul>
        </nav>
  </aside>
);

export default Sidenav;
